var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_featured_modules_teaching_package_unit_mine bbox d-flex justify-space-between align-stretch cp",
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "div",
            {
              staticClass:
                "placeholder bbox d-flex justify-space-between align-stretch",
            },
            [
              _vm.picture
                ? _c("img", {
                    staticClass: "img flex-shrink-0 mr-10",
                    attrs: { src: `${_vm.$img_url}${_vm.picture}`, alt: "" },
                  })
                : _vm._e(),
              !_vm.picture
                ? _c(
                    "div",
                    {
                      ref: "no_picture",
                      staticClass:
                        "no_picture_group un_sel bbox d-flex align-center justify-center flex-shrink-0 mr-10",
                    },
                    [
                      _c("div", { staticClass: "pic_title" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.title) + "\n        "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "info_group d-flex flex-column align-start justify-space-between",
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("div", {
                    staticClass: "describe",
                    domProps: { innerHTML: _vm._s(_vm.content) },
                  }),
                  _c(
                    "div",
                    { staticClass: "data_group pt-6 d-flex align-center" },
                    [
                      _vm.sale
                        ? [
                            _c("img", {
                              staticClass: "icon mr-4",
                              attrs: {
                                src: require("@/assets/images/shopping_mail/sale_icon.png"),
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "text mr-10" }, [
                              _vm._v(_vm._s(_vm.sale)),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.readamount
                        ? [
                            _c("img", {
                              staticClass: "icon mr-4",
                              attrs: {
                                src: require("@/assets/images/shopping_mail/view_icon.png"),
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.readamount)),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
          _c("hover-module", {
            staticClass: "hover_module",
            attrs: { pselected: _vm.pselected, ppackage: _vm.ppackage },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }