<template>
  <teaching-package
  ></teaching-package>
</template>

<script>
import TeachingPackage from '@/views/ShoppingMail/TeachingPackage/index.vue';
export default {
  components: {
    TeachingPackage,
  }
}
</script>
