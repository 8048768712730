var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_teaching_package_home" },
    [
      _vm.carousel_list && _vm.carousel_list.length
        ? _c("carousel", {
            staticClass: "carousel_wrapper",
            attrs: { list: _vm.carousel_list },
            on: { handleClick: _vm.handleClickCarousel },
          })
        : _vm._e(),
      _vm.new_list && _vm.new_list.length
        ? _c(
            "div",
            { staticClass: "section" },
            [
              _c("title-module", {
                staticClass: "section_title_group",
                attrs: { title: "最新上架", show_more: false },
              }),
              _c(
                "div",
                { staticClass: "list_group d-flex align-start flex-wrap" },
                [
                  _vm._l(_vm.new_list, function (item, index) {
                    return [
                      index <= 5
                        ? _c("teaching-package", {
                            key: index,
                            staticClass: "teaching_package mb-10",
                            attrs: { ppackage: item },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.clickPackage(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.new_list && _vm.new_list.length
        ? _c(
            "div",
            { staticClass: "section" },
            [
              _c("title-module", {
                staticClass: "section_title_group",
                attrs: { title: "本周最热", show_more: false },
              }),
              _c(
                "div",
                { staticClass: "list_group d-flex align-start flex-wrap" },
                [
                  _vm._l(_vm.hot_list, function (item, index) {
                    return [
                      index <= 5
                        ? _c("teaching-package", {
                            key: index,
                            staticClass: "teaching_package mb-10",
                            attrs: { ppackage: item },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.clickPackage(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.all_list && _vm.all_list.length
        ? _c(
            "div",
            { staticClass: "section" },
            [
              _c("title-module", {
                staticClass: "section_title_group",
                attrs: { title: "全部" },
                on: {
                  handleMore: function ($event) {
                    return _vm.clickMore({
                      name: "全部课程",
                      only: true,
                      type: "teaching_package",
                    })
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "list_group d-flex align-start flex-wrap" },
                [
                  _vm._l(_vm.all_list, function (item, index) {
                    return [
                      _c("unit-mini", {
                        key: index,
                        staticClass: "unit_mini mb-20 mr-10",
                        attrs: { ppackage: item },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clickPackage(item)
                          },
                        },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "nomore" }, [_vm._v("————  已经到底啦  ————")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }