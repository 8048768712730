<template>
  <div
    class="views_shopping_mail_teaching_package_modules_carousel d-flex align-center justify-space-between bbox px-20"
    ref="wrapper"
  >
    <div class="button prev"
      @click.stop="doPrev"
    ></div>
    <div class="carousel_wrapper bbox px-20">
      <swiper ref="mySwiper" :options="swiperOptions"
        class="swiper_group"
        @click.stop.native="handleClickSwiper()"
      >
        <template
          v-for="(item,index) in list"
        >

          <slide-module
            :key="index"
            :data="item"
            :height="height"
          ></slide-module>
        </template>
      </swiper>
    </div>
    <div class="button next"
      @click.stop="doNext"
    ></div>
    <div class="swiper-pagination" slot="pagination"></div>
  </div>
</template>

<script>
import { Swiper } from 'vue-awesome-swiper';
import SlideModule from '@/views/ShoppingMail/Modules/Swiper/Featured/Slide.vue';
import 'swiper/css/swiper.css';
export default {
  props: {
    width: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: undefined,
    },
    list: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
  },
  components: {
    Swiper,
    SlideModule,
  },
  data(){
    return {
      swiperOptions: {
        allowTouchMove: true,
        slidesPerView: 3,
        spaceBetween: 40,
        loopAdditionalSlides: 0,
        // effect : 'flip',
        loop: true,
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },

  },
  methods: {
    doPrev(){
      this.swiper.slidePrev();
    },
    doNext(){
      this.swiper.slideNext();
    },
    handleClickSwiper(){
      const {list} = this;
      const {length} = list;
      const {clickedIndex} = this.swiper; // 点击的index对总长度取余
      this.$emit('handleClick', this.list[(clickedIndex + 1)%length]);
    }
  },
  mounted(){
    setTimeout(()=>{
      this.swiper.update();
    },50)
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_teaching_package_modules_carousel{
  width: 100%;
  // max-width: 1200px;
  padding: {
    left: 40px;
    right: 40px;
  }
  .carousel_wrapper{
    overflow: hidden;
    flex: 1;
  }
  .button{
    @include background(10px, 17px);
    width: 30px;
    height: 76px;
    border-radius: 4px;
    flex-shrink: 0;
    background: {
      color: #f9f9f9;
    }
    &:hover{
      background: {
        color: #ebebeb;
      }
    }
    &.prev{
      background: {
        image: url(~@/assets/images/shopping_mail/carousel/btn_prev.png);
      }
    }
    &.next{
      background: {
        image: url(~@/assets/images/shopping_mail/carousel/btn_next.png);
      }
    }
  }
}
</style>
<style lang="scss">
.views_shopping_mail_teaching_package_modules_carousel{
  position: relative;
  .swiper-pagination{
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-pagination-bullet{
    margin: 0 4px;
    cursor: pointer;
    &.swiper-pagination-bullet-active{
      background-color: $main_blue;
    }
  }
}
</style>
