<template>
  <div
    class="views_shopping_mail_featured_modules_teaching_package_unit_mine bbox d-flex justify-space-between align-stretch cp"
  >
    <div class="wrapper">
      <div class="placeholder bbox d-flex justify-space-between align-stretch">
        <img
          v-if="picture"
          :src="`${$img_url}${picture}`"
          alt=""
          class="img flex-shrink-0 mr-10"
        >
        <div
          v-if="!picture"
          class="no_picture_group un_sel bbox d-flex align-center justify-center flex-shrink-0 mr-10"
          ref="no_picture"
        >
          <div class="pic_title">
            {{ title }}
          </div>
        </div>
        <div class="info_group d-flex flex-column align-start justify-space-between">
          <div class="title">{{title}}</div>
          <div class="describe " v-html="content"></div>
          <div class="data_group pt-6 d-flex align-center" >
            <template
              v-if="sale"
            >
              <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon mr-4">
              <div class="text mr-10">{{sale}}</div>
            </template>
            <template
              v-if="readamount"
            >

              <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon mr-4">
              <div class="text">{{readamount}}</div>
            </template>
          </div>
        </div>
      </div>
      <hover-module
        class="hover_module"
        :pselected="pselected"
        :ppackage="ppackage"

      ></hover-module>
    </div>
  </div>
</template>

<script>
import HoverModule from './Hover.vue';
export default {
  components: {
    HoverModule,
  },
  props: {
    pselected: {
      type: Object,
      default: undefined,
    },
    ppackage: {
      type: Object,
      default: undefined,
    }
  },
  computed: {
    picture(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.pic;
      }
      if(ppackage){
        return ppackage.webpic;
      }
      return '';
    },
    title(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.title;
      }
      if(ppackage){
        return ppackage.title;
      }
      return '';
    },
    content(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.content;
      }
      if(ppackage){
        return ppackage.content;
      }
      return '';
    },
    create_time(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.createTime;
      }
      if(ppackage){
        return ppackage.createTime;
      }
      return '';
    },
    sale(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage.sale;
      }
      if(ppackage){
        return ppackage.sale;
      }
      return undefined;
    },
    readamount(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage.readamount;
      }
      if(ppackage){
        return ppackage.readamount;
      }
      return undefined;
    }
  }

}
/**
 * 288*5 + 20*4 = 1520
 * width 288/1520 = 18.9%
 * margin-right = 1.32%
 */
</script>

<style lang="scss" scoped>
.views_shopping_mail_featured_modules_teaching_package_unit_mine{
  .wrapper{
    @include transition;
    transition-delay: 600;
    width: 100%;
    float: left;
    position: relative;
    .placeholder{
      @include transition;
      position: relative;
      z-index: 2;
      opacity: 1;
    }
    .hover_module{
      @include transition(0.1s);
      // transition-delay: 0.3s;
      opacity: 0;
      top:0;
      left: 0;
      transform-origin: top center;
      right: 0;
      position: absolute;
      z-index: 3;
      transform: scaleY(0);
    }
    &:hover{
      overflow: unset;
      .hover_module{
        opacity: 1;
        transform: scaleY(1);
        top: -80px;
        left: -40px;
        right: -40px;
      }
    }
  }
  .img{
    width: 160px;
    height: 90px;
    border-radius: 4px;
  }
  .no_picture_group{
    width: 160px;
    height: 90px;
    //display: block;
    border-radius: 4px;
    background-color: #aaa;
    .pic_title{
      font-size: 18px;
      color: #fff;
      width: 100%;
      padding: 0 10px;
      text-align: center;
      word-break: break-all;
    }
  }
  .info_group{
    word-break: break-all;
    flex: 1;
    overflow: auto;
    .title{
      @include single_line_intercept;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      width: 100%;
    }
    .describe{
      @include multi_line_intercept(2);
      font-size: 12px;
      color: #999999;
      line-height: 20px;
      height: 40px;
      overflow: hidden;
    }
    .data_group{
      font-size: 12px;
      color: #666666;
      line-height: 16px;
    }
  }
}
</style>
