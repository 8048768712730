var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass:
        "views_shopping_mail_teaching_package_modules_carousel d-flex align-center justify-space-between bbox px-20",
    },
    [
      _c("div", {
        staticClass: "button prev",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doPrev.apply(null, arguments)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "carousel_wrapper bbox px-20" },
        [
          _c(
            "swiper",
            {
              ref: "mySwiper",
              staticClass: "swiper_group",
              attrs: { options: _vm.swiperOptions },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClickSwiper()
                },
              },
            },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c("slide-module", {
                    key: index,
                    attrs: { data: item, height: _vm.height },
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "button next",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doNext.apply(null, arguments)
          },
        },
      }),
      _c("div", {
        staticClass: "swiper-pagination",
        attrs: { slot: "pagination" },
        slot: "pagination",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }