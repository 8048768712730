<template>
  <div
    class="views_shopping_mail_teaching_package_home"
  >
    <carousel
      v-if="carousel_list && carousel_list.length"
      class="carousel_wrapper"
      :list="carousel_list"
      @handleClick="handleClickCarousel"
    ></carousel>
    <!-- 最新上架 -->
    <div class="section"
      v-if="new_list && new_list.length"
    >
      <title-module
        class="section_title_group"
        title="最新上架"
        :show_more="false"
      ></title-module>
      <div class="list_group d-flex align-start flex-wrap">
        <template
          v-for="(item,index) in new_list"
        >
          <teaching-package
            @click.stop.native="clickPackage(item)"
            class="teaching_package mb-10"
            :key="index"
            :ppackage="item"
            v-if="index<=5"
          ></teaching-package>
        </template>
      </div>
    </div>
    <!-- 本周最热 -->
    <div class="section"
      v-if="new_list && new_list.length"
    >
      <title-module
        class="section_title_group"
        title="本周最热"
        :show_more="false"
      ></title-module>
      <div class="list_group d-flex align-start flex-wrap">
        <template
          v-for="(item,index) in hot_list"
        >
          <teaching-package
            @click.stop.native="clickPackage(item)"
            class="teaching_package mb-10"
            :key="index"
            :ppackage="item"
            v-if="index<=5"
          ></teaching-package>
        </template>
      </div>
    </div>
    <!-- 全部 -->
    <div class="section"
      v-if="all_list && all_list.length"
    >
      <title-module
        class="section_title_group"
        title="全部"
        @handleMore="clickMore({name: '全部课程', only: true, type: 'teaching_package'})"
      ></title-module>
      <div class="list_group d-flex align-start flex-wrap">
        <template
          v-for="(item,index) in all_list"
        >
          <unit-mini
            :ppackage="item"
            :key="index"
            class="unit_mini mb-20 mr-10"
            @click.stop.native="clickPackage(item)"
          ></unit-mini>
        </template>
      </div>
    </div>
    <div class="nomore">————  已经到底啦  ————</div>
  </div>
</template>

<script>
import Carousel from './Modules/Carousel.vue';
import TitleModule from '@/views/ShoppingMail/Modules/Common/Title.vue';
import TeachingPackage from '@/views/ShoppingMail/Modules/TeachingPackage/Unit/Major.vue';
import UnitMini from '@/views/ShoppingMail/Modules/TeachingPackage/Unit/Mini.vue';
import {PRotation, PPackage} from '@/types';
export default {
  components: {
    Carousel,
    TitleModule,
    TeachingPackage,
    UnitMini,
  },
  data(){
    return {
      carousel_list: [],
      new_list: [],
      hot_list: [],
      all_list: [],
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    async init(){
      await this.getData();
    },
    async getData(){
       const res = await this.$global.doPost({
        url: '/projectShop/packageHome',
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {rotations,packagesall,packageshot,packagesnew} = contents;
        const carousel_list = this.$types.transformObjectForList({
          list: rotations,
          obj : PRotation,
        })
        const new_list = this.$types.transformObjectForList({
          list: packagesnew,
          obj : PPackage,
        })
        const hot_list = this.$types.transformObjectForList({
          list: packageshot,
          obj : PPackage,
        })
        const all_list = this.$types.transformObjectForList({
          list: packagesall,
          obj : PPackage,
        })
        this.$set(this, 'carousel_list', carousel_list);
        this.$set(this, 'new_list', new_list);
        this.$set(this, 'hot_list', hot_list);
        this.$set(this, 'all_list', all_list);
      }
      return res;
    },
    clickPackage(item){
      // 追加 package 及id
      // this.$emit('handlePushNavigation', {type:'package', package_data: item});
      this.$api.shopping_mail.pushNav({type: 'teaching_package', package_data: item});
    },
    clickMore(params){
      const {name, only, type} = params;
      this.$api.shopping_mail.pushNav({type: 'search', search_data:{
        name,
        only,
        type,
      }})
    },
    handleClickCarousel(item){
      this.clickPackage({id: item.content, title: item.title});
    }
  },
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_teaching_package_home {
  width: 100%;
  padding-bottom: 100px;
  background-color: #fff;
  .carousel_wrapper{
    padding: {
      top: 20px;
      bottom: 30px;
    }
    margin: 0 auto;
  }
  .section{
    padding: 0 40px;
  }
  .section_title_group{
    padding-top: 30px;
  }
  .list_group{
    padding: 30px 0 40px;
  }
  .teaching_package{
    width: 18.9%;
    margin-right: 1.32%;
    &:nth-of-type(5n){
      margin-right: 0;
    }
  }
  .unit_mini{
    width: 360px;
  }
  .nomore{
    width: 100%;
    font-size: 16px;
    color: #D5D9DB;
    line-height: 21px;
    text-align: center;
    padding: 50px 0;
  }
}
</style>
